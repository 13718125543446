<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      layout: 'none-layout',
    }
  },
  watch: {
    $route(to) {
      this.layout = to.meta.layout || 'none-layout';
    }
  },
  async mounted() {
    this.layout = this.$route.meta.layout || 'none-layout';
  },
};
</script>