import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Intro',
  //   component: () => import(/* webpackChunkName: "Intro" */ '@/views/Intro.vue'),
  //   meta: { layout: 'none-layout' },
  // },
  {
    path: '/',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue'),
    meta: { layout: 'default-layout' },
  },
  {
    path: '/ecosystem',
    name: 'Ecosystem',
    component: () => import(/* webpackChunkName: "Ecosystem" */ '@/views/Ecosystem.vue'),
    meta: { layout: 'default-layout' },
  },
  {
    path: '/ecosystem-details',
    name: 'EcosystemDetails',
    component: () => import(/* webpackChunkName: "EcosystemDetails" */ '@/views/EcosystemDetails.vue'),
    meta: { layout: 'default-layout' },
  },
  {
    path: '/developer',
    name: 'Developer',
    component: () => import(/* webpackChunkName: "Developer" */ '@/views/Developer.vue'),
    meta: { layout: 'default-layout' },
  },
  {
    path: '/getMEV',
    name: 'GetMEV',
    component: () => import(/* webpackChunkName: "GetMEV" */ '@/views/GetMEV.vue'),
    meta: { layout: 'default-layout' },
  },
  {
    path: '/meverse_privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/privacy/Privacy.vue'),
    meta: { layout: 'default-layout' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
        return {selector: to.hash}
    } else {
        return { x: 0, y: 0 }
    }
  },
})

export default router
